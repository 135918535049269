import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';

import AudioComponent from '../../../components/AudioComponent/AudioComponent';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DocumentView from '../../../Molecules/DocumentView/DocumentView';
import {
  formatBytes,
  getBreadCrumb,
  removeHtmlTag,
} from '../../../Utils/Utils';
import IntroPage from '../../../components/ContentType/IntroPage/IntroPage';
import Layout from '../../../Layout';
import PageBanner from '../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import useMedias from '../../../hooks/useMedias';
import TitleContact from '../../../Molecules/TitleContact/TitleContact';
import ContactFormDefaultPage from '../../../components/ContactForm/ContactFormDefaultPage';

let classNames = require('classnames');

const FondationColas = ({ data }) => {
  const intl = useIntl();
  const { getImage, getDocument } = useMedias();

  const imagesArray = data?.allImages?.edges;
  const documentsArray = data.allDocuments.edges;
  const pageData = data?.pageData?.edges[0].node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData.path?.alias);
  const metaTags = pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });
  const [innerNavList, setInnerNavList] = useState([]);
  const [allText, setAllText] = useState('');

  let globalText = '';
  let sectionArr = [];
  let leftRightBlocks = [];
  let sectionCount = 0;

  useEffect(() => {
    setInnerNavList(sectionArr);
    setAllText(
      removeHtmlTag(
        `${pageData.title}.${pageData.field_sous_titre !== null &&
          pageData.field_sous_titre !== undefined
          ? pageData.field_sous_titre
          : ''
        }.${globalText}`
      )
    );
  }, []);

  return (
    <Layout>
      <Seo
        title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
        description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'principaux_enjeux')}>
        <ScrollPageNav data={innerNavList} />
        <PageBanner
          visuel={getImage(
            imagesArray,
            pageData?.relationships?.field_image?.drupal_internal__mid
          )}
          alt={pageData.relationships?.field_image?.field_media_image?.alt || ''}
          scroll={true}
        >
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                //gparent: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
                parentPage: {
                  title: breadCrumb?.parent?.name,
                  url: breadCrumb?.parent?.link,
                },
                currentPage: {
                  title: breadCrumb?.current?.name,
                  url: breadCrumb?.current?.link,
                },
                locale: pageData.langcode,
              }}
            />

            <div
              className={classNames({
                title_audio:
                  allText &&
                  pageData.field_version_audio &&
                  allText.length > 30,
              })}
            >
              <TitlePage
                color="color_white"
                title={pageData.title}
                description={pageData.field_sous_titre}
              />
              {allText && pageData.field_version_audio && allText.length > 30 && (
                <AudioComponent
                  text={intl.formatMessage({
                    id: 'detailsActivities.button.listen.label',
                  })}
                  textToRead={allText}
                  color="color_white"
                />
              )}
            </div>
          </div>
        </PageBanner>

        {pageData.relationships?.field_blocs?.map((block, i) => {
          if (block.field_title?.processed) {
            sectionCount++;
            sectionArr.push({
              title: block.field_title?.processed,
              section: 'scroll_to_' + sectionCount,
            });
          }

          switch (block.__typename) {
            case 'block_content__block_texte':
              leftRightBlocks = [];
              globalText += `${block.field_title?.processed}.${block.field_subtitle?.processed != undefined &&
                block.field_subtitle?.processed != null
                ? block.field_subtitle?.processed
                : ''
                }.${block.body?.processed != undefined &&
                  block.body?.processed != null
                  ? block.body?.processed
                  : ''
                }.`;
              block.relationships?.field_section?.forEach((item) => {
                globalText += `${item.relationships?.field_bloc_texte[0]?.field_title
                  ?.processed != undefined
                  ? item.relationships?.field_bloc_texte[0]?.field_title
                    ?.processed
                  : ''
                  }.${item.relationships?.field_bloc_texte[0]?.field_bloc_text_1
                    .processed
                  }.`;
                leftRightBlocks.push({
                  text: item.relationships?.field_bloc_texte[0]
                    ?.field_bloc_text_1.processed,
                  title:
                    item.relationships?.field_bloc_texte[0]?.field_title
                      ?.processed,
                  visuel: getImage(
                    imagesArray,
                    item?.relationships?.field_image?.drupal_internal__mid
                  ),
                  alt: item.relationships?.field_image?.field_media_image?.alt || '',
                  arrowColor:
                    item.relationships?.field_bloc_texte[0]?.field_theme_title,
                  imageAlignment: item.field_alignment,
                  imageNoPadding: item.field_no_padding,
                  field_backgroud_color: block.field_backgroud_color,
                  linkInfo: item.relationships?.field_bloc_texte[0]
                    ?.field_link != null && {
                    ctaText:
                      item.relationships?.field_bloc_texte[0]?.field_link
                        ?.title,
                    ctaLink:
                      item.relationships?.field_bloc_texte[0]?.field_link?.url,
                    ctaTarget:
                      item.relationships?.field_bloc_texte[0]
                        ?.field_nouvelle_fenetre,
                  },
                });
              });

              return (
                <div key={i}>
                  {block.relationships?.field_section?.length === 0 ? (
                    <IntroPage
                      customClass={'scroll_to_' + sectionCount}
                      title={block.field_title?.processed}
                      subtitle={block.field_subtitle?.processed}
                      visuel={getImage(
                        imagesArray,
                        block.relationships?.field_image?.drupal_internal__mid
                      )}
                      alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                      text={block.body?.processed}
                      size="border" //wrapper
                    />
                  ) : block.relationships?.field_section?.length === 1 ? (
                    <section
                      className={classNames(
                        'section_content',
                        'block_4_section',
                        'scroll_to_' + sectionCount
                      )}
                    >
                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={block.field_title?.processed}
                          type="arrow"
                          h2color={
                            block.field_backgroud_color === 'navy' ? 'white' : 'bleu'
                          }
                          h4color={
                            block.field_backgroud_color === 'navy' ? 'white' : 'bleu'
                          }
                        />
                        {leftRightBlocks.map((secBlock, l) => (
                          <TextVisuelTwoCol
                            key={l}
                            extremeLeft={
                              secBlock?.imageNoPadding &&
                                secBlock?.imageAlignment === 'left'
                                ? true
                                : false
                            }
                            extremeRight={
                              secBlock?.imageNoPadding &&
                                secBlock?.imageAlignment === 'right'
                                ? true
                                : false
                            }
                            orderInverse={
                              secBlock?.imageAlignment === 'right'
                                ? true
                                : false
                            }
                            visuel={secBlock.visuel}
                            alt={secBlock.alt || ''}
                            title={secBlock.title}
                            text={secBlock.text}
                            cta={secBlock.linkInfo ? secBlock.linkInfo : null}
                          />
                        ))}
                      </div>
                    </section>
                  ) : (
                    <section
                      className={classNames(
                        'section_content',
                        'scroll_to_' + sectionCount
                      )}
                    >
                      <ShapeBackground
                        top={block.field_border_top && 'left'}
                        color={
                          block.field_backgroud_color === 'ice'
                            ? 'catskill_white'
                            : block.field_backgroud_color === 'navy'
                              ? 'dark_bleu' //z-index-top
                              : 'white'
                        }
                        bottomInfinite={block.field_infinite_border}
                        bottom={block.field_border_bottom_display}
                      >
                        <div className="wrapper_page_xs">
                          <TitleSection
                            title={block.field_title?.processed}
                            type="arrow"
                            h2color={
                              block.field_backgroud_color === 'navy' ? 'white' : 'bleu'
                            }
                            h4color={
                              block.field_backgroud_color === 'navy' ? 'white' : 'bleu'
                            }
                          />

                          {leftRightBlocks.map((secBlock, k) => (
                            <TextVisuelTwoCol
                              key={k}
                              title={secBlock.title}
                              extremeLeft={
                                secBlock?.imageNoPadding &&
                                  secBlock?.imageAlignment === 'left'
                                  ? true
                                  : false
                              }
                              extremeRight={
                                secBlock?.imageNoPadding &&
                                  secBlock?.imageAlignment === 'right'
                                  ? true
                                  : false
                              }
                              orderInverse={
                                secBlock?.imageAlignment === 'right'
                                  ? true
                                  : false
                              }
                              visuel={secBlock.visuel}
                              alt={secBlock.alt}
                              //alignLeft={secBlock?.imageNoPadding && secBlock?.imageAlignment === 'left' ? true : false}
                              //alignRight={secBlock?.imageNoPadding && secBlock?.field_alignment === 'right' ? true : false}
                              alignTop={true}
                              text={secBlock.text}
                              colorTitle={
                                secBlock.field_backgroud_color === 'navy'
                                  ? 'color_white'
                                  : undefined
                              }
                              colorDescription={
                                secBlock.field_backgroud_color === 'navy' &&
                                'color_white'
                              }
                              cta={secBlock.linkInfo ? secBlock.linkInfo : null}
                            />
                          ))}
                        </div>
                      </ShapeBackground>
                    </section>
                  )}
                </div>
              );

            case 'block_content__block_document':
              let docArr = [];
              block.relationships?.field_file?.forEach((file) => {
                docArr.push({
                  name: getDocument(documentsArray, file?.drupal_internal__mid)
                    ?.field_media_document?.description,
                  size: `(.pdf ${formatBytes(
                    getDocument(documentsArray, file?.drupal_internal__mid)
                      ?.filesize
                  )})`,
                  link: getDocument(documentsArray, file?.drupal_internal__mid)
                    ?.uri.url,
                });
              });
              return (
                <section
                  className={classNames('section_content', 'block_3_section')}
                  key={i}
                >
                  <div className="wrapper_page_xs">
                    <TitleSection title="Documents" color="color_white" />
                    <DocumentView data={docArr} />
                  </div>
                </section>
              );

            default:
              break;
          }
        })}
        {
          pageData.field_display_form_innovation &&
          <section className={classNames('section_content', 'section_contact_form')}>
            <div className="wrapper_page">
              <div style={{ textAlign: 'center' }}>
                <TitleContact
                  removeicon={true}
                  title={intl.formatMessage({
                    id: 'contactexpertise.form.title',
                  })}
                />
              </div>
              <div className="col" style={{ 'width': '350px', 'margin': '0 auto' }}>
                <div className="contact_form_component_container">
                  <ContactFormDefaultPage title={pageData.title} formName="contact_innovation" langCode={pageData.langcode} />
                </div>
              </div>
            </div>
          </section>
        }
      </div>

    </Layout>
  );
};

export const query = graphql`
  query fondationTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
    $documentsID: [Int!]
  ) {
    pageData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          langcode
          field_type
          field_padding
          field_sous_titre
          field_version_audio
          field_display_form_innovation
          path {
            alias
            langcode
          }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          relationships {
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_texte {
                  id
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_border_top
                  field_border_bottom_display
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_section {
                      field_no_padding
                      field_alignment

                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                        field_bloc_texte {
                          field_theme_title
                          field_nouvelle_fenetre
                          field_title {
                            processed
                          }
                          field_bloc_text_1 {
                            processed
                          }
                          field_link {
                            title
                            url
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_document {
                  relationships {
                    field_file {
                      drupal_internal__mid
                    }
                  }
                }
              }
            }
          }
          body {
            processed
          }
        }
      }
    }

    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel        
              }
            }
          }
        }
      }
    }
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default FondationColas;
